import {HeaderConfig} from '@core/components/header/header.config';

// Default configurations for each feature module
export class ModuleHeaderConfigs {
    static readonly DASHBOARD = new HeaderConfig('Dashboard', 'fas fa-th-large');
    static readonly TICKETS = new HeaderConfig('Tickets', 'fas fa-tasks');
    static readonly CUSTOMERS = new HeaderConfig('Kunden', 'fas fa-user-circle');
    static readonly TIMETRACKING = new HeaderConfig('Zeiterfassung', 'fas fa-user-clock');
    static readonly PROJECTS = new HeaderConfig('Projekte', 'fas fa-puzzle-piece');
    static readonly HUMANRESOURCES = new HeaderConfig('Personalwesen', 'far fa-building');
    static readonly WAREHOUSE = new HeaderConfig('Warenwirtschaft', 'fas fa-cube');
    static readonly KNOWLEDGEMANAGEMENT = new HeaderConfig('Wissensmanagement', 'fas fa-book');
    static readonly REPORTING = new HeaderConfig('Reporting', 'fas fa-chart-line');
    static readonly MANAGEMENT = new HeaderConfig('Verwaltung', null, 'verwaltung_white', 'NAV.MANAGEMENT', true, true);
    static readonly SETTING = new HeaderConfig('Einstellungen', 'fas fa-cogs');
    static readonly PROFILE = new HeaderConfig('Profil', 'fas fa-user');
    static readonly NOTIFICATIONS = new HeaderConfig('Benachrichtigungs-Verwaltung', 'fas fa-bell');

    static readonly HIDE_HEADER = {isVisible: false};
}
